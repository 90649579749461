/* Global typography
   ========================================================================== */

html {
  font-size: 16px;
  line-height: 1.5;
  font-family: $font-family-default;
  font-style: normal;
  font-weight: $font-weight-light-default;
  color: $color-body-text;
}

a {
  text-decoration: none;
  @extend %transition;
  @include link-colors($color-link, $hover: $color-link-hover);
}

a:not(.btn) {
  // Make body links bold
  .page-block p &,
  .page-block ul &,
  .page-block ol &,
  body.wp-editor p &,
  body.wp-editor ul &,
  body.wp-editor ol & {
    border-bottom: 1px solid $color-link;
  }
}

::-webkit-input-placeholder {
  opacity: 0.7 !important;
  color: $color-body-text !important;
  font-size: 16px !important;
}

:-moz-placeholder { /* Firefox 18- */
  opacity: 0.7 !important;
  color: $color-body-text !important;
  font-size: 16px !important;
}

::-moz-placeholder {  /* Firefox 19+ */
  opacity: 0.7 !important;
  color: $color-body-text !important;
  font-size: 16px !important;
}

:-ms-input-placeholder {
  opacity: 0.7 !important;
  color: $color-body-text !important;
  font-size: 16px !important;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  border-top: 1px solid $black;
  border-bottom: none;
  @include rms-value($properties: (margin-top: $ms-small-spacing, margin-bottom: $ms-small-spacing));
  @include mq($breakpoint-medium) {
    @include rms-value($properties: (margin-top: $ms-large-spacing, margin-bottom: $ms-large-spacing));
  }
}

address {
  font-style: normal;
}

time {
  display: inline;
}

menu,
ol,
ul {
  @include rms-value($properties: (padding-left: 1));
  list-style-position: inside;
}

dl,
menu,
ol,
ul,
p,
pre,
address,
.stand-out {
  margin: 0; // Reset margins
  @include rms-value($properties: (margin-bottom: 0));
}

ins {
  text-decoration: none;
}

.title,
legend,
h1,h2,h3,h4,h5,h6 {
  @extend %title;
}

.title--large {
  @include rms-value($properties: (font-size: 5));

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (font-size: 8));
  }
}

h1,
h2,
.title--1 {
  @include rms-value($properties: (font-size: 4));

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (font-size: 6));
  }
}

.title--2 {
  @include rms-value($properties: (font-size: 3));

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (font-size: 5));
  }
}

h3,
h4,
.title--3 {
  @include rms-value($properties: (font-size: 2));

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (font-size: 3));
  }
}

.title--4,
h5,
h6 {
  @include rms-value($properties: (font-size: 0));
}

h1,h2,h3 {
  @include rms-value($properties: (line-height: 3));
}

h1 + h2,
h1 + h3,
h2 + h3,
h2 + time.title--tertiary {
  margin-top: 0;
}

// Give headings a margin-top if they are preceded by a p, ul or ol element.
@include generate-heading-adjacent-siblings(p ul ol figure) {
  @include rms-value($properties: (margin-top: 6));
}

// Give headings a margin-top if they are preceded by another heading element.
@include generate-heading-adjacent-siblings(h1 h2 h3 h4 h5 h6) {
  @include rms-value($properties: (margin-top: 0));
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  border-bottom: none !important;
}

.title--border {
  border-bottom: 5px solid $superlight-grey;
  @include rms-value($properties: (padding-bottom: -10, margin-bottom: -3));

  .accent-bg & {
    border-color: darken($superlight-grey, 10%);
  }

  &--inline {
    display: inline-block;
  }
}

.fa-ul .fa {
  color: $color-primary;
}

.white-text {
  color: #FFF;
}

.blue-text {
  color: $blue;
}

.yellow-text {
  color: $yellow;
}

.green-text {
  color: $green;
}

.red-text {
  color: $red;
}

blockquote {
  @include rms-value($properties: (padding-top: 0, padding-bottom: 0, padding-left: 0, padding-right: 0), $em-values: true);
  margin: 0;
  border-left: 5px solid $blue;

  &.testimonial,
  .wysiwyg-text & {
    background: lighten($blue, 50%);
    color: darken($blue, 30%);
  }

  .wysiwyg-text & {
    @include rms-value($properties: (margin-top: 6, margin-bottom: 6));
  }

  p {
    font-style: italic;
  }

  *:last-child {
    margin-bottom: 0;
  }

  .cite {
    @include rms-value($properties: (padding-top: 0));
    font-style: normal;
    border-top: 1px solid $color-body-text;
    display: block;

    &__name {
      font-weight: $font-weight-normal-default;
    }
  }
}

.no-list-style {
  @extend %no-list-style;
}

legend {
  @include rms-value($properties: (font-size: 1));
  line-height: 1;
}

.fa-list-style {
  @extend .title--3;
}

.affwp-form label {
  @extend legend;
  display: block;
}

.align-center {
  text-align: center;
}

.warning-text {
  color: #ffa500;
}

.fa--space-r {
  @include rms-value($properties: (margin-right: -5));
}

.fa--space-l {
  @include rms-value($properties: (margin-left: -5));
}
