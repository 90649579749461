.testimonials-grid {
  @include rms-value($properties: (gap: 4));
  display: grid;

  @include mq($breakpoint-small) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (gap: 6));
  }

  @include mq($breakpoint-large-alt) {
    @include rms-value($properties: (gap: 8));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  blockquote {
    margin: 0;
    display: flex;
    flex-direction: column;
    background: lighten($blue, 50%);
    color: darken($blue, 30%);

    p {
      flex-grow: 1;
    }
  }
}
