.wp-caption {
  max-width: 100%;
}

.wp-caption-text,
.wp-caption-dd {
  @include rms-value($properties: (margin-top: -6));
  text-align: center;
  font-style: italic;
}

.blog-intro-img {
  padding-bottom: 56.25%;
  position: relative;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.wysiwyg-text {
  @include mq($breakpoint-medium) {
    @include rms-value($properties: (font-size: 1));
    line-height: 1.7;
  }
}

.article-list {
  @include rms-value($properties: (gap: 4));
  display: grid;

  @include mq($breakpoint-tiny-alt) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (gap: 6));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include mq($breakpoint-large) {
    @include rms-value($properties: (gap: 8));
  }
}

.article-card {
  @extend %last-child-spacing;
  border: 1px solid $lighter-grey;
  padding: 1rem;
  color: inherit;
  display: block;

  &__img {
    padding-bottom: 56.25%;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    color: inherit;
  }
}