// ==========================================================================
// Mixins
// Thanks to @sutterlity
// https://github.com/sutter/POPY-Starter-integration-web/blob/master/scss/tools/_mixins.scss
// ==========================================================================

/**
 * SMACSS Theming
 * @example scss
 * .foobar {
 *   background: #ff3377;
 *   @include theme(beccapurple){
 *     background: #663399;
 *   }
 * }
 * @param {String} $name - theme name
 */

@mixin theme($name) {
  @if $theme == $name {
     @content;
  }
}

/**
 * Buttons
 * This customises your buttons with a different background color and text color.
 * If no text color is specified it will default to white.
 * @param {String} $color-background - background color
 * @param {String} $color-text (#fff) - text color
 * @example scss
 * .foobar { @include btn(#f37) { ... } }
 */

@mixin btn($color-background, $color-text: #fff) {
  background-color: $color-background;
  border-color: darken($color-border, 6%);
  color: $color-text;
}

/**
 * Media Queries
 * Allows you to use inline media queries.
 * @link http://jakearchibald.github.com/sass-ie/
 * @param {String} $min-width-breakpoint - min width breakpoint
 * @param {String} $max-width-breakpoint - max width breakpoint
 * @param {String} $default-query - first breakpoint query type
 * @example scss
 * .foobar { @include mq(20em, 30em) { ... } }
 */

@mixin mq($min-width-breakpoint, $max-width-breakpoint: null, $default-query-type: min-width) { // breakpoints can be a variable
  // If a min and max width have been set
  @if $max-width-breakpoint {
    @if $fix-mqs {
      @if $fix-mqs >= $min-width-breakpoint and $fix-mqs <= $max-width-breakpoint { // ...and if the fixed breakpoint is between the query...
        @content; // ...output the content the user gave us.
      }
    }
    @else {
      @media (min-width: #{$min-width-breakpoint}) and (max-width: #{$max-width-breakpoint}) { @content; }
    }
  }
  // If just a min width has been set
  @else {
    @if $fix-mqs {
      @if $fix-mqs >= $min-width-breakpoint { // ...and if the fixed breakpoint is greater than query...
        @content; // ...output the content the user gave us.
      }
    }
    @else {
      @media (#{$default-query-type}: #{$min-width-breakpoint}) { @content; }
    }
  }
 }

/**
 * IE Specific Styles
 * @example scss
 * .foobar {
 *   .lt-ie8 & { @include old-ie { ... } }
 *   .lt-ie9 & { @include old-ie { ... } }
 * }
 */

@mixin old-ie {
  @if $old-ie {
     @content;
  }
}

/**
 * Sass version of Sticky Footer by Ryan Fait
 * @link http://ryanfait.com/sticky-footer/
 * @param {String} $footer_height - height of footer including padding or borders
 * @param {String} $root_selector (.site) - main wrapper element
 * @param {String} $root_footer_selector (.push) - hidden element that "pushes" down the footer
 * @param {String} $footer_selector (footer) - footer element
 * @example scss
 * .foobar { @include sticky-footer(4em) { ... } }
 */

@mixin sticky-footer($footer_height, $root_selector:".site", $root_footer_selector:".push", $footer_selector:"footer") {
  html, body {height: 100%;}
  #{$root_selector} {
    width: 100%;
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -#{$footer_height};
    #{$root_footer_selector} {
      height: #{$footer_height};
    }
  }
  #{$footer_selector} {
    clear: both;
    position: relative;
    height: #{$footer_height};
  }
}

/**
 * @param {number} $opacity
 * @example scss
 * .foobar { @include opacity(4) { ... } }
 */
@mixin opacity($opacity) {
  filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
  opacity: $opacity;
}

/**
 * @link http://bit.ly/thoughtbot-bourbon-inline-block
 * @require {mixin} old-ie
 * @param {String} $alignment (baseline)
 * @example scss
 * .foobar { @include inline-block() { ... } }
 */

@mixin inline-block($alignment: baseline) {
  display: inline-block;
  @if $alignment and $alignment != none {
    vertical-align: $alignment;
  }
  @include old-ie {
    zoom:1;
    *display:inline;
    *vertical-align:auto;
  }
}

/**
 * RGBA Fallback
 * @param {String} $color
 * @param {String} $percent
 * @example scss
 * .foobar { @include rgba-bg(#f37,.9) { ... } }
 */

@mixin rgba-bg($color, $percent) {
  background: $color;
  background: rgba($color, $percent);
}

/**
 * BEM element
 * @param {String} $element
 * @example scss
 * @include e(element) { ... } }
 */

@mixin e($element) {
  &__#{$element} {
    @content;
  }
}

/**
 * BEM modifier
 * @param {String} $modifier
 * @example scss
 * @include e(element) { ... } }
 */

@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}

/**
 * Generate a set of modular scale classes
 * @param {number} $amount
 * @example scss
 * @include ms-classes(8) }
 */

@mixin ms-classes($amount) {
  @for $i from 1 through $amount {
    .ms-#{$i} { font-size: ms($i); }
  }
}

/**
 * Output responsive modular scale properties
 * @param {Array} $properties
 * @param {String} $ms-ratio
 * @example scss
 * .foobar { @include rms-value($properties: (padding-top: 2, padding-bottom: 2)) }
 */
 
@mixin rms-value($properties, $ms-ratio: $ms-ratio-default, $minus-values: false, $em-values: false) {

	@each $property-name, $property-ms in $properties {
	
	  $em-value: ms($property-ms, 1em, $ms-ratio);
	  $rem-value: ms($property-ms, $ms-base, $ms-ratio);
	  $px-value: rem-to-px($rem-value);
	  
	  // Output line height value as em so the value is relative (useful for heading heights)
	  @if $property-name == line-height {

      #{$property-name}: $em-value;
	  
	  } @else {
  	  
  	  @if $minus-values == true {
    	  
    	  @if $em-values == true {
	  
    	    #{$property-name}: -$em-value;

        } @else {
          
    	    // Provide pixel fallback
    	    #{$property-name}: -$px-value;
          #{$property-name}: -$rem-value;
          
        }
        
      
      } @else {
        
    	  @if $em-values == true {
	  
    	    #{$property-name}: $em-value;

        } @else {
          
    	    // Provide pixel fallback
    	    #{$property-name}: $px-value;
          #{$property-name}: $rem-value;
          
        }
        
      }
		  
	  }
	  
	}

}

/**
 * Generate columns with a right margin (if not using flexbox)
 * @param {Number} $columns
 * @param {Number} $margin-size
 * @param {boolean} $flexbox
 * @example scss
 * .foobar { @include generate-columns(4, 10)) }
 */

@mixin generate-columns($columns, $margin-r-size, $margin-btm-size: false) {
  $margin-total: ($columns - 1) * strip-unit($margin-r-size);
  $margin: ($margin-total / ($columns - 1)) / 100; // Divide final amount by 100 to use as percentage
  $column-width: ((100 - $margin-total) / $columns) / 100; // Divide final amount by 100 to use as percentage

  width: percentage($column-width);
  margin-right: percentage($margin);  
  display: inline-block;
  vertical-align: top;
  
  // Set the last item in the row with a right margin of 0
  &:nth-of-type(#{$columns}n) {
  	margin-right: 0;
  }
  
  @if $margin-btm-size != false {
    @include rms-value($properties: (margin-bottom: $margin-btm-size));
    
    &:nth-of-type(#{$columns}n+1):nth-last-of-type(-n+#{$columns}),
    &:nth-of-type(#{$columns}n+1):nth-last-of-type(-n+#{$columns}) ~ & {
      margin-bottom: 0;
    }
  }

}

/**
 * Generate nav borders for list items
 * @param {Number} $margin
 * @example scss
 * .foobar { @include nav-borders(10px)) }
 */
 
@mixin nav-borders($margin) {
  &:after {
    content: "|";
    margin-left: $margin;
    margin-right: $margin;
  }
  
  &:last-child:after {
    content: "";
    margin-right: 0;
  }
}

/**
 * Generate rgba background with IE filter
 * @param {String} $color
 * @param {Number} $alpha
 * @example scss
 * .foobar { @include transparent-bg(#000, 0.8)) }
 */

@mixin transparent-bg($color, $alpha) {
  $rgba: rgba($color, $alpha);
  $ie-hex-str: ie-hex-str($rgba);
  background-color: $color;
  background-color: $rgba;
  @include old-ie {
    background-color: transparent;
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
    zoom: 1;
  }
}

@mixin link-colors($color-link, $hover: $color-link-hover) {
  color: $color-link;

  &:hover {
    color: $color-link-hover;
  }
};

// Mixin to generate adjacent sibling styles, i.e. p + h1. Accepts a list (i.e. p ul ol) or single element.
@mixin generate-heading-adjacent-siblings($element) {
  @if(type-of($element) == list) {
    @each $current-element in $element {
      #{$current-element} + h1,
      #{$current-element} + h2,
      #{$current-element} + h3,
      #{$current-element} + h4,
      #{$current-element} + h5,
      #{$current-element} + h6 {
        @content
      }
    }
  } @else {
    #{$element} + h1,
    #{$element} + h2,
    #{$element} + h3,
    #{$element} + h4,
    #{$element} + h5,
    #{$element} + h6 {
      @content
    }
  }
}