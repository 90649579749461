/*
 * jQuery FlexSlider v2.6.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flexslider .slides:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  position: relative;
  zoom: 1;
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
  text-align: center;
  
  @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
    @include rms-value($properties: (margin-top: 0));
  }
  
  li {
    display: inline-block; 
    &:first-child {
      @include rms-value($properties: (margin-right: 10));
    }
      
    @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
      display: inline-block; 
      
      &:first-child {
        @include rms-value($properties: (margin-right: 10));
      }
    }
  }
}
.flex-direction-nav a {
  @extend .title--large;
  border: none !important;
  text-decoration: none;
  display: inline-block;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: $color-primary;
  
  .side-arrows & {
    @include mq($breakpoint-tiny-alt) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.flex-direction-nav .flex-prev {
  left: -10%;
  right: auto;
}
.flex-direction-nav .flex-next {
  right: -10%;
  left: auto;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  color: $yellow;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  color: $yellow;
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a.flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  @include rms-value($properties: (margin-top: $ms-small-spacing));
  width: 100%;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 20px;
  height: 20px;
  display: block;
  background: darken($superlight-grey, 10%);
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: $yellow;
}
.flex-control-paging li a.flex-active {
  background: $color-primary;
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}