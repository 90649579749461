/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu oncanvas CSS
*/
.mm-hidden {
  display: none !important; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu,
.mm-menu > .mm-panel {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.mm-menu {
  background: inherit;
  display: block;
  overflow: hidden;
  padding: 0; }

.mm-panel {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease;
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }
  .mm-panel.mm-opened {
    -webkit-transform: translate3d(0%, 0, 0);
    -moz-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0); }
  .mm-panel.mm-subopened {
    -webkit-transform: translate3d(-30%, 0, 0);
    -moz-transform: translate3d(-30%, 0, 0);
    -ms-transform: translate3d(-30%, 0, 0);
    -o-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0); }
  .mm-panel.mm-highest {
    z-index: 1; }

.mm-menu > .mm-panel {
  background: inherit;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 20px; }
  .mm-menu > .mm-panel.mm-hasnavbar {
    padding-top: 40px; }
  .mm-menu > .mm-panel:before, .mm-menu > .mm-panel:after {
    content: '';
    display: block;
    height: 20px; }

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important; }

.mm-vertical .mm-listview .mm-panel,
.mm-listview .mm-vertical .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after,
  .mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after {
    border-color: transparent; }

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
  display: block; }

.mm-vertical .mm-listview > li > .mm-next,
.mm-listview > li.mm-vertical > .mm-next {
  height: 40px;
  bottom: auto; }
  .mm-vertical .mm-listview > li > .mm-next:after,
  .mm-listview > li.mm-vertical > .mm-next:after {
    top: 16px;
    bottom: auto; }
.mm-vertical .mm-listview > li.mm-opened > .mm-next:after,
.mm-listview > li.mm-vertical.mm-opened > .mm-next:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.mm-navbar {
  border-bottom: 1px solid transparent;
  background: $color-title-text;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }
  .mm-navbar > * {
    display: block;
    padding: 10px 0; }
  .mm-navbar a, .mm-navbar a:hover {
    text-decoration: none; }
  .mm-navbar .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .mm-navbar .mm-btn {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    z-index: 1; }
    .mm-navbar .mm-btn:first-child {
      padding-left: 20px;
      left: 0; }
    .mm-navbar .mm-btn:last-child {
      padding-right: 20px;
      right: 0; }

.mm-panel .mm-navbar {
  display: none; }
.mm-panel.mm-hasnavbar .mm-navbar {
  display: block; }

.mm-listview,
.mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }
  
  .mm-listview li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

.mm-listview {
  font: inherit;
  font-size: inherit }
  .mm-listview a,
  .mm-listview a:hover {
    text-decoration: none; }
  .mm-listview > li {
    position: relative; }
    .mm-listview > li > a,
    .mm-listview > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      line-height: 20px;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; }
      .mm-listview > li > a.mm-arrow,
      .mm-listview > li > span.mm-arrow {
        padding-right: 50px; }
    .mm-listview > li:not(.mm-divider):after {
      content: '';
      border-bottom-width: 1px;
      border-bottom-style: solid;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }
    .mm-listview > li:not(.mm-divider):after {
      left: 20px; }
  .mm-listview .mm-next {
    background: rgba(3, 2, 1, 0);
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2; }
    .mm-listview .mm-next:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0; }
    .mm-listview .mm-next + a,
    .mm-listview .mm-next + span {
      margin-right: 50px; }
    .mm-listview .mm-next.mm-fullsubopen {
      width: 100%; }
      .mm-listview .mm-next.mm-fullsubopen:before {
        border-left: none; }
      .mm-listview .mm-next.mm-fullsubopen + a,
      .mm-listview .mm-next.mm-fullsubopen + span {
        padding-right: 50px;
        margin-right: 0; }

.mm-menu > .mm-panel > .mm-listview {
  margin-left: -20px;
  margin-right: -20px; }
  .mm-menu > .mm-panel > .mm-listview.mm-first {
    margin-top: -20px; }
  .mm-menu > .mm-panel > .mm-listview.mm-last {
    padding-bottom: 20px; }

.mm-prev:before,
.mm-next:after,
.mm-arrow:after {
  content: '';
  border: 2px solid transparent;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 20px; }

.mm-next:after,
.mm-arrow:after {
  border-top: none;
  border-left: none;
  right: 20px; }

.mm-divider {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px; }

.mm-listview > li.mm-spacer {
  padding-top: 40px; }
  .mm-listview > li.mm-spacer > .mm-next {
    top: 40px; }
  .mm-listview > li.mm-spacer.mm-divider {
    padding-top: 25px; }

.mm-menu {
  background: $color-primary;
  color: $white; }
  .mm-menu .mm-navbar {
    border-color: #FFF; }
    .mm-menu .mm-navbar > *,
    .mm-menu .mm-navbar a {
      color: $white; }
    .mm-menu .mm-navbar .mm-btn:before,
    .mm-menu .mm-navbar .mm-btn:after {
      border-color: $color-secondary; }
  .mm-menu .mm-listview > li:after {
    border-color: transparent; }
  .mm-menu .mm-listview > li .mm-prev:before,
  .mm-menu .mm-listview > li .mm-next:after,
  .mm-menu .mm-listview > li .mm-arrow:after {
    border-color: $color-secondary; }
  .mm-menu .mm-listview > li .mm-prev:after,
  .mm-menu .mm-listview > li .mm-next:before {
    border-color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
  .mm-menu .mm-listview > li.mm-selected > span {
    background: rgba(255, 255, 255, 0.5); }
  .mm-menu.mm-vertical .mm-listview li.mm-opened > a.mm-next,
  .mm-menu.mm-vertical .mm-listview li.mm-opened > .mm-panel,
  .mm-menu .mm-listview li.mm-opened.mm-vertical > a.mm-next,
  .mm-menu .mm-listview li.mm-opened.mm-vertical > .mm-panel {
    background: rgba(0, 0, 0, 0.05); }
  .mm-menu .mm-divider {
    background: rgba(0, 0, 0, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-opened {
  overflow: hidden;
  position: relative; }
  html.mm-opened body {
    overflow: hidden; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }
.mm-menu.mm-current {
  display: block; }

.mm-menu {
  width: 80%;
  min-width: 140px;
  max-width: 440px; }

html.mm-opening .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -moz-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  -o-transform: translate(80%, 0);
  transform: translate(80%, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -moz-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    -o-transform: translate(140px, 0);
    transform: translate(140px, 0); } }
@media all and (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -moz-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    -o-transform: translate(440px, 0);
    transform: translate(440px, 0); } }
    
/*
	jQuery.mmenu effects extension CSS
*/
html.mm-effect-zoom-menu .mm-menu.mm-offcanvas {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }
html.mm-effect-zoom-menu.mm-opened .mm-menu.mm-offcanvas {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -moz-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -ms-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -o-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }
html.mm-effect-zoom-menu.mm-opening .mm-menu.mm-offcanvas {
  -webkit-transform: scale(1, 1) translate3d(0%, 0, 0);
  -moz-transform: scale(1, 1) translate3d(0%, 0, 0);
  -ms-transform: scale(1, 1) translate3d(0%, 0, 0);
  -o-transform: scale(1, 1) translate3d(0%, 0, 0);
  transform: scale(1, 1) translate3d(0%, 0, 0); }
html.mm-effect-zoom-menu.mm-right.mm-opened .mm-menu.mm-offcanvas {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -moz-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -ms-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -o-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }
html.mm-effect-zoom-menu.mm-right.mm-opening .mm-menu.mm-offcanvas {
  -webkit-transform: scale(1, 1) translate3d(0%, 0, 0);
  -moz-transform: scale(1, 1) translate3d(0%, 0, 0);
  -ms-transform: scale(1, 1) translate3d(0%, 0, 0);
  -o-transform: scale(1, 1) translate3d(0%, 0, 0);
  transform: scale(1, 1) translate3d(0%, 0, 0); }

html.mm-effect-slide-menu .mm-menu.mm-offcanvas {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease; }
html.mm-effect-slide-menu.mm-opened .mm-menu.mm-offcanvas {
  -webkit-transform: translate3d(-30%, 0, 0);
  -moz-transform: translate3d(-30%, 0, 0);
  -ms-transform: translate3d(-30%, 0, 0);
  -o-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0); }
html.mm-effect-slide-menu.mm-opening .mm-menu.mm-offcanvas {
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0); }
html.mm-effect-slide-menu.mm-right.mm-opened .mm-menu.mm-offcanvas {
  -webkit-transform: translate3d(30%, 0, 0);
  -moz-transform: translate3d(30%, 0, 0);
  -ms-transform: translate3d(30%, 0, 0);
  -o-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0); }
html.mm-effect-slide-menu.mm-right.mm-opening .mm-menu.mm-offcanvas {
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0); }

.mm-menu.mm-effect-zoom-panels .mm-panel {
  -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  -moz-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  -ms-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  -o-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition-property: -webkit-transform, left;
  -moz-transition-property: -moz-transform, left;
  -ms-transition-property: -ms-transform, left;
  -o-transition-property: -o-transform, left;
  transition-property: transform, left; }
  .mm-menu.mm-effect-zoom-panels .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1) translate3d(0%, 0, 0);
    -moz-transform: scale(1, 1) translate3d(0%, 0, 0);
    -ms-transform: scale(1, 1) translate3d(0%, 0, 0);
    -o-transform: scale(1, 1) translate3d(0%, 0, 0);
    transform: scale(1, 1) translate3d(0%, 0, 0); }
    .mm-menu.mm-effect-zoom-panels .mm-panel.mm-opened.mm-subopened {
      -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
      -moz-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
      -ms-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
      -o-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
      transform: scale(0.7, 0.7) translate3d(-30%, 0, 0); }

.mm-menu.mm-effect-slide-panels-0 .mm-panel.mm-subopened {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu.mm-effect-slide-panels-100 .mm-panel.mm-subopened {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

/*
	jQuery.mmenu navbar addon CSS
*/
.mm-menu > .mm-navbar {
  padding: 0;
  z-index: 3;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-navbar-bottom {
  border-top: 1px solid transparent;
  border-bottom: none;
  top: auto;
  bottom: 0; }

.mm-navbar-top ~ .mm-navbar-top {
  border-bottom: none; }

.mm-navbar-bottom ~ .mm-navbar-bottom {
  border-top: none; }

.mm-navbar.mm-hasbtns {
  padding: 0 40px; }

.mm-navbar-top-1 {
  top: 0px; }

.mm-hasnavbar-top-1 .mm-panel {
  top: 40px; }
.mm-hasnavbar-top-1 .mm-indexer {
  top: 50px; }
.mm-hasnavbar-top-1 .mm-fixeddivider {
  top: 40px; }

.mm-navbar-top-2 {
  top: 40px; }

.mm-hasnavbar-top-2 .mm-panel {
  top: 80px; }
.mm-hasnavbar-top-2 .mm-indexer {
  top: 90px; }
.mm-hasnavbar-top-2 .mm-fixeddivider {
  top: 80px; }

.mm-navbar-top-3 {
  top: 80px; }

.mm-hasnavbar-top-3 .mm-panel {
  top: 120px; }
.mm-hasnavbar-top-3 .mm-indexer {
  top: 130px; }
.mm-hasnavbar-top-3 .mm-fixeddivider {
  top: 120px; }

.mm-navbar-bottom-1 {
  bottom: 0px; }

.mm-hasnavbar-bottom-1 .mm-panel {
  bottom: 40px; }
.mm-hasnavbar-bottom-1 .mm-indexer {
  bottom: 50px; }

.mm-navbar-bottom-2 {
  bottom: 40px; }

.mm-hasnavbar-bottom-2 .mm-panel {
  bottom: 80px; }
.mm-hasnavbar-bottom-2 .mm-indexer {
  bottom: 90px; }

.mm-navbar-bottom-3 {
  bottom: 80px; }

.mm-hasnavbar-bottom-3 .mm-panel {
  bottom: 120px; }
.mm-hasnavbar-bottom-3 .mm-indexer {
  bottom: 130px; }

.mm-close {
  text-align: right; }
  .mm-close:after
   {
    //@extend .fa;
    //content: $fa-var-times-circle;
  }

.mm-navbar-2 > *,
.mm-navbar-3 > *,
.mm-navbar-4 > *,
.mm-navbar-5 > *,
.mm-navbar-6 > * {
  display: block;
  float: left; }

.mm-navbar-2 > * {
  width: 50%; }

.mm-navbar-3 > * {
  width: 33.33%; }

.mm-navbar-4 > * {
  width: 25%; }

.mm-navbar-5 > * {
  width: 20%; }

.mm-navbar-6 > * {
  width: 16.66%; }
