//  clearfix
.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.small-container {
  @extend %last-child-spacing;
  @extend %first-child-spacing;
  width: 90%;
  margin: 0 auto;
  max-width: 900px;

  &--small-screen-max-width {
    @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
      max-width: 100%;
      width: 100%;
    }
  }

  &--no-center {
    margin-left: 0;
    margin-right: auto;
  }
}

.page-block {
  @extend %page-block-padding;
  @extend %last-child-spacing;
  @extend %first-child-spacing;
  @extend %container;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}

.bg-img {
  @extend %bg-img;
  position: relative;
  background-attachment: fixed;
  background-position: center top;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  color: #fff;
  min-height: 400px;
  min-height: 50vh;
  z-index: 10;

  .is-touch & {
    background-attachment: scroll;
    background-position: center center;
  }

  &--large-height {
    @include mq($breakpoint-medium) {
      min-height: 500px;
      min-height: 70vh;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 10px;
    background: rgba($black, 0.1);
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 10px;
    background: rgba($black, 0.1);
  }

  &__text {
    @extend %absolute-center-vertical-only;
  }

  @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
    @include rms-value(
      $properties: (
        padding-top: $ms-small-spacing,
        padding-bottom: $ms-small-spacing,
      )
    );
  }

  @include mq($breakpoint-medium) {
    @include rms-value(
      $properties: (
        font-size: 1,
      )
    );
  }
}

.margin-top {
  @include rms-value(
    $properties: (
      margin-top: $ms-small-spacing,
    )
  );
  @include mq($breakpoint-medium) {
    @include rms-value(
      $properties: (
        margin-top: $ms-large-spacing,
      )
    );
  }
}

.margin-btm {
  @include rms-value(
    $properties: (
      margin-bottom: $ms-small-spacing,
    )
  );
  @include mq($breakpoint-medium) {
    @include rms-value(
      $properties: (
        margin-bottom: $ms-large-spacing,
      )
    );
  }
}

.margin-top-alt {
  @include rms-value(
    $properties: (
      margin-top: $ms-small-spacing,
    )
  );
}

.margin-btm-alt {
  @include rms-value(
    $properties: (
      margin-bottom: $ms-small-spacing,
    )
  );
}

.margin-btm-small {
  @include rms-value(
    $properties: (
      margin-bottom: 0,
    )
  );
}

.margin-top-small {
  @include rms-value(
    $properties: (
      margin-top: 0,
    )
  );
}

.padding-top-alt {
  @include rms-value(
    $properties: (
      padding-top: $ms-small-spacing,
    )
  );
}

.no-btm-padding {
  padding-bottom: 0 !important;
}

.no-btm-margin {
  margin-bottom: 0 !important;
}

.no-top-margin {
  margin-top: 0 !important;
}

.no-top-padding {
  padding-top: 0 !important;
}

.entry-content-asset,
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  @include rms-value(
    $properties: (
      margin-top: 6,
      margin-bottom: 6,
    )
  );
}

.entry-content-asset,
.video-container {
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.alignnone {
  @include rms-value(
    $properties: (
      margin-top: 6,
      margin-bottom: 6,
    )
  );
}

.alignleft {
  margin: 0;
  @include rms-value(
    $properties: (
      margin-right: $ms-small-spacing,
      margin-bottom: $ms-small-spacing,
    )
  );
  @include mq($breakpoint-small) {
    float: left;
  }
}

.alignright {
  margin: 0;
  @include rms-value(
    $properties: (
      margin-left: $ms-small-spacing,
      margin-bottom: $ms-small-spacing,
    )
  );
  @include mq($breakpoint-small) {
    float: right;
  }
}

.aligncenter {
  display: block;
  margin: 0;
  @include rms-value(
    $properties: (
      margin-top: $ms-small-spacing,
      margin-bottom: $ms-small-spacing,
    )
  );
  @include mq($breakpoint-small) {
    margin-left: auto;
    margin-right: auto;
  }
}

.white-bg {
  background: #fff;
}

.accent-bg {
  background: $superlight-grey;
}

.service-points {
  @extend %title-font;
  @extend %no-list-style;
  @include rms-value(
    $properties: (
      font-size: 1,
    )
  );
  color: $color-accent;

  &--inline {
    li {
      @include mq($breakpoint-small) {
        display: inline-block;
        line-height: 2;
        @include rms-value(
          $properties: (
            margin-right: 3,
          )
        );
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.breadcrumb {
  @extend .clearfix;
  padding: 0.8em 1.6em;
  background: $superlight-grey;
  display: flex;
  flex-wrap: wrap;

  @include mq($breakpoint-small) {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
  }

  &__path {
    @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
      order: 2;
    }

    @include mq($breakpoint-small) {
      flex-grow: 1;
      padding-top: 8px;
    }
  }

  span:last-of-type {
    font-weight: $font-weight-semibold-default;
  }

  .search-box {
    @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
      @include rms-value(
        $properties: (
          margin-bottom: 0,
        )
      );
      order: 1;
      width: 100%;
    }

    @include mq($breakpoint-small) {
      min-width: 260px;
    }

    @include mq($breakpoint-medium) {
      @include rms-value(
        $properties: (
          margin-left: 0,
        )
      );
    }
  }
}

#ajaxsearchpro1_1 .probox .proinput input.autocomplete,
#ajaxsearchpro1_2 .probox .proinput input.autocomplete,
div.ajaxsearchpro[id*="ajaxsearchpro1_"] .probox .proinput input.autocomplete,
#ajaxsearchpro1_1 .probox .proinput input.orig,
#ajaxsearchpro1_2 .probox .proinput input.orig,
div.ajaxsearchpro[id*="ajaxsearchpro1_"] .probox .proinput input.orig {
  font-size: 16px !important;
  line-height: 1.5;
  font-family: $font-family-default !important;
  font-style: normal !important;
  font-weight: $font-weight-light-default !important;
  color: $color-body-text !important;
}

div.ajaxsearchpro[id*="ajaxsearchpro1_"] {
  background: #fff !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 1px solid $color-title-text !important;
}

div.ajaxsearchpro[id*="ajaxsearchpro1_"] .probox {
  padding: 4px 2px !important;
}

.bundled_product_checkbox {
  margin-right: 0.5rem;
}

.screen-reader-text {
  @extend %visuallyhidden;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.pagination {
  padding: 10px 20px !important;
  margin-bottom: 20px !important;
  background: $superlight-grey;
  text-align: center;

  ul {
    @extend %no-list-style;
  }

  li {
    display: inline-block;
  }
}

.pagination a,
#sort-filter a {
  color: inherit;
}

.pagination .current,
#sort-filter .selected {
  font-weight: bold;
}

.pagination .page-numbers {
  padding: 0 5px;
  display: inline-block;
}

.pagination .prev {
  float: left;
}

.pagination .next {
  float: right;
}

.button-group {
  display: inline-flex;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
}

body.wp-editor {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
