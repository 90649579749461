// ==========================================================================
// Layout classes
// ==========================================================================

// main container
%container {
  width: 90%;
  margin: 0 auto;
  position: relative;
  max-width: 1500px;
}

%page-block-padding {
  @include rms-value($properties: (padding-top: $ms-small-spacing, padding-bottom: $ms-small-spacing));

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (padding-top: $ms-large-spacing, padding-bottom: $ms-large-spacing));
  }
}

%large-page-block-padding {
  @include rms-value($properties: (padding-top: $ms-small-spacing, padding-bottom: $ms-small-spacing));

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (padding-top: $ms-xlarge-spacing, padding-bottom: $ms-xlarge-spacing));
  }
}

%page-block-margin {
  @include rms-value($properties: (margin-top: $ms-small-spacing, margin-bottom: $ms-small-spacing));

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (margin-top: $ms-large-spacing, margin-bottom: $ms-large-spacing));
  }
}

// inline-block columns
%ib-col {
  display: inline-block;
  vertical-align: top;
}

%last-child-spacing {
  > *:last-child,
  > *:last-child > *:last-child,
  > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
  }
}

%first-child-spacing {
  > *:first-child,
  > *:first-child > *:first-child,
  > *:first-child > *:first-child > *:first-child {
    margin-top: 0;
  }
}

%small-scr-block-item-spacing {
  @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
    @include rms-value($properties: (margin-bottom: $ms-small-spacing));
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

%bg-img {
	background-size: cover;
	position: relative;
}

// ==========================================================================
// Title classes
// ==========================================================================

%title-font {
  font-family: $font-family-display;
  font-style: normal;
  font-weight: $font-weight-normal-display;
  color: $color-title-text;
}

%title {
	@extend %title-font;
	margin: 0; // Reset margins
	@include rms-value($properties: (margin-top: 2, margin-bottom: -10));
}

// ==========================================================================
// Menu classes
// ==========================================================================

%horizontal-menu-item {
  display: inline-block;
  @include rms-value($properties: (margin-right: 0));
  
  &:last-child {
    margin-right: 0
  }
}

// ==========================================================================
// Link & transition classes
// ==========================================================================

%inherit-link-color {
  color: inherit;
  text-decoration: none;
  
  &:hover {
    color: $color-link-hover;
  }
}

%transition {
  transition: all 0.5s ease-in-out;
}

// ==========================================================================
// Image replacement classes
// ==========================================================================

%ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;/* IE 6/7 fallback */
  *text-indent: -9999px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
  }
}

%no-list-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

// make sure container is [position: relative]
%absolute-center {
  @include mq($breakpoint-medium) {
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

%absolute-center-vertical-only {
  @include mq($breakpoint-medium) {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

%absolute-center-horizontal-only {
  @include mq($breakpoint-medium) {
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    transform: translateX(-50%);
  }
}

// Hide from both screenreaders and browsers: h5bp.com/u

%hidden {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v

%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout

%invisible {
  visibility: hidden;
}
