.ribbon-container {
  position: relative;
  display: block;
  overflow: hidden;
  border-bottom: none !important;
}

.corner-ribbon{
  width: 200px;
  background: $green;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  font-weight: $font-weight-bold-default;
  color: $white;
  transform: rotate(-45deg);

  &--family-favorite {
    background: $purple;
  }

  &--travel-size {
    background: $yellow;
  }

  &--best-seller {
    background: $blue;
  }

  &--coming-soon {
    background: $orange;
  }
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

.corner-ribbon.top-left{
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
}

.corner-ribbon.top-right{
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
}