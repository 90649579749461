.grecaptcha-badge { visibility: hidden; }

[class*=col] {
  @extend %last-child-spacing;
  @extend %first-child-spacing;
  @extend %small-scr-block-item-spacing;
}

.col-2 {
  @include mq($breakpoint-medium) {
    @include generate-columns(2, 10, $ms-large-spacing);
  }
}

.col-2-address {
  @include mq($breakpoint-tiny) {
    @include generate-columns(2, 10, $ms-large-spacing);
  }
}

.col-2-keep-small-scr {
  
  @include mq($breakpoint-tiny, $breakpoint-small - 1px) {
    @include generate-columns(2, 2.5);
  }
  
  @include mq($breakpoint-small, $breakpoint-medium - 1px) {
    @include generate-columns(2, 5);
  }
  
  @include mq($breakpoint-medium) {
    @include generate-columns(2, 10);
  }
  
}

.col-4 {
  @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
    @include generate-columns(2, 2.5, $ms-small-spacing);
  }
  
  @include mq($breakpoint-small, $breakpoint-medium - 1px) {
    @include generate-columns(4, 5, $ms-small-spacing);
  }
  
  @include mq($breakpoint-medium, $breakpoint-large - 1px) {
    @include generate-columns(4, 5, $ms-large-spacing);
  }
  
  @include mq($breakpoint-large) {
    @include generate-columns(4, 10, $ms-large-spacing);
  }
}

.col-5 {
  @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
    @include generate-columns(2, 2.5, $ms-small-spacing);
  }
  
  @include mq($breakpoint-small, $breakpoint-medium - 1px) {
    @include generate-columns(3, 5, $ms-small-spacing);
  }
  
  @include mq($breakpoint-medium, $breakpoint-large - 1px) {
    @include generate-columns(5, 2.5, $ms-large-spacing);
  }
  
  @include mq($breakpoint-large) {
    @include generate-columns(5, 3, $ms-large-spacing);
  }
}

.col-6 {
  @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
    @include generate-columns(2, 2.5, $ms-small-spacing);
  }
  
  @include mq($breakpoint-small, $breakpoint-medium - 1px) {
    @include generate-columns(3, 5, $ms-small-spacing);
  }
  
  @include mq($breakpoint-medium, $breakpoint-large - 1px) {
    @include generate-columns(3, 5, $ms-large-spacing);
  }
  
  @include mq($breakpoint-large) {
    @include generate-columns(6, 3, $ms-large-spacing);
  }
}

.col-7 {
  @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
    @include generate-columns(2, 2.5, $ms-small-spacing);
  }

  @include mq($breakpoint-small, $breakpoint-medium - 1px) {
    @include generate-columns(4, 5, $ms-small-spacing);
  }

  @include mq($breakpoint-medium, $breakpoint-large - 1px) {
    @include generate-columns(4, 5, $ms-large-spacing);
  }

  @include mq($breakpoint-large) {
    @include generate-columns(7, 2.5, $ms-large-spacing);
  }
}

.product-in-slider {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  
  @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
    padding: 0 1.25%;
  }
  
  @include mq($breakpoint-small, $breakpoint-medium - 1px) {
    padding: 0 2.5%;
  }
  
  @include mq($breakpoint-medium) {
    padding: 0 5%;
  }
}

.products-wrap {
  @include rms-value($properties: (gap: 4));
  display: grid;

  @include mq($breakpoint-tiny) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq($breakpoint-small) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (gap: 6));
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include mq($breakpoint-large) {
    @include rms-value($properties: (gap: 8));
  }
  
  .product {
    display: flex;
    flex-direction: column;
  }

  .product-border {
    border: 1px solid $lighter-grey;
    padding: 1rem;
    flex-grow: 1;
  }
}

.featured-products-wrap {
  @include rms-value($properties: (gap: 4));
  display: grid;

  @include mq($breakpoint-tiny) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq($breakpoint-medium) {
    @include rms-value($properties: (gap: 6));
  }

  @include mq($breakpoint-large) {
    @include rms-value($properties: (gap: 8));
  }

  .product {
    display: flex;
    flex-direction: column;
  }

  .product-border {
    border: 1px solid $lighter-grey;
    padding: 1rem;
    flex-grow: 1;
  }
}

.subcategories-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @include mq($breakpoint-small) {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .subcategory {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    width: calc(100% / 2);

    @include mq($breakpoint-small) {
      width: calc(100% / 3);
      padding: 1rem;
    }

    @include mq($breakpoint-medium) {
      width: calc(100% / 5);
    }

    &:last-of-type {
      @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
        width: 100%;
      }
    }

    &__link {
      @extend %first-child-spacing;
      @include rms-value($properties: (padding-top: -6, padding-bottom: -6, padding-left: 0, padding-right: 0), $em-values: true);
      display: block;
      border: 1px solid $purple;
      flex-grow: 1;

      * {
        color: $purple;
      }

      &--active {
        background-color: $purple;

        * {
          color: $white;
        }
      }
    }
  }
}

.show-small-and-up {
  @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
    display: none !important;
  }
}