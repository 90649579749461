.btn {
  @extend %transition;
  @extend %title-font;
  @include rms-value($properties: (font-size: 1));
  font-weight: $font-weight-normal-display;
  @include rms-value($properties: (padding-top: -6, padding-bottom: -6, padding-left: 0, padding-right: 0), $em-values: true);
  background: $purple;
  text-shadow: none;
	outline: none;
	display: inline-block;
	line-height: 1;
	cursor: pointer;
	margin-bottom: 0.33em;
  color: #FFF;
	border-radius: 3px;
	box-shadow: 0px 0.33em darken($purple, 10%);
	width: auto;
	
  &:hover {
    background: $green;
    box-shadow: 0px 0.33em darken($green, 10%);
    color: #FFF !important;
  }
  
  &:active {
    position: relative;
    top: 3px;
  }
  
  &--yellow {
    background: $yellow;
    box-shadow: 0px 0.33em darken($yellow, 10%);
    color: darken($green, 25%) !important;
  }
  
  &--grey {
    background: $lighter-grey;
    box-shadow: 0px 0.33em darken($lighter-grey, 10%);
    color: $black !important;
  }
  
  &--block {
    display: block;
    width: 100%;
  }
  
  &--small {
    @include rms-value($properties: (font-size: 0));
  }
  
  &--large {
    @include rms-value($properties: (font-size: 2));
  }
  
  &--xlarge {
    @include rms-value($properties: (font-size: 4));
  }
}

//For woocommerce buttons

.button {
  border-bottom: none !important;
  @extend .btn;
  @extend .btn--large;
}

.stock_alert_button {
  @extend .btn;
}