footer {  
  position: relative;
	background: darken($blue, 20%);
	
	&:before {
  	content: '';
  	position: absolute;
  	left: 0;
  	top: 0;
  	right: 0;
  	height: 10px;
  	background: rgba($black, 0.1);
	}
	
	p a {
  	border-bottom: none !important;
	}
}

.footer {
  @extend .page-block;
  color: #FFF;

  @include e(col) {
    @include rms-value($properties: (margin-bottom: $ms-small-spacing));
      
    @include mq($breakpoint-tiny, $breakpoint-small - 1px) {
      @include generate-columns(2, 3);
    }
      
    @include mq($breakpoint-small, $breakpoint-medium - 1px) {
      @include generate-columns(4, 2.5);
    }
    
    @include mq($breakpoint-medium) {
      @include generate-columns(4, 5);
    }
  }
  
  h4 {
    color: #FFF;
    border-bottom: 3px solid $orange;
    @include rms-value($properties: (padding-bottom: -10, margin-bottom: -3));
    margin-top: 0;
  }
  
  ul {
    @extend %no-list-style;
    
    li {
      a {
        display: block;
        border-bottom: 1px solid rgba(#FFF, 0.2) !important;
        @include rms-value($properties: (padding-top: -6, padding-bottom: -6), $em-values: true);
      }
      
      &:last-child {
        a {
          border-bottom: none !important;
        }
      }
    }
  }
  
  a {
    @extend %inherit-link-color;
    font-weight: $font-weight-light-default !important;
  }
  
  @include mq($breakpoint-small) {      
    @include e(copyright-bar) {
      display: flex;
      justify-content: space-between;
    }
  }
  
}