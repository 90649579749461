.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  @include rms-value($properties: (gap: 2));

  @include mq($breakpoint-small) {
    @include rms-value($properties: (gap: 4));
  }

  @include mq($breakpoint-medium) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid $lighter-grey;
    @include rms-value($properties: (padding: 1));

    &::after {
      content: '';
      padding-bottom: 56.25%;
      display: block;
    }

    img {
      width: 120px;
      height: 60px;
      object-fit: contain;
      object-position: center;

      @include mq($breakpoint-medium) {
        width: 160px;
        height: 80px;
      }
    }
  }
}
