.checkout-login {
  @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
    width: 100%;
  }

  background: $superlight-grey;
  @include rms-value(
    $properties: (
      padding-top: -2,
      padding-bottom: -2,
      padding-left: 0,
      padding-right: 0,
    ),
    $em-values: true
  );
}

.checkout-box {
  @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
    width: 100%;
  }

  @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
    &:not(:only-child):not(:last-of-type) {
      @include rms-value(
        $properties: (
          margin-bottom: $ms-small-spacing,
        )
      );
    }
  }

  @include mq($breakpoint-tiny-alt, $breakpoint-medium - 1px) {
    @include generate-columns(2, 5);
  }

  @include mq($breakpoint-medium) {
    @include generate-columns(2, 10);
  }

  &:only-child {
    width: auto;
    display: block;
    margin-right: 0;
  }

  h3 {
    @include rms-value(
      $properties: (
        padding-top: -6,
        padding-bottom: -6,
        padding-left: 0,
        padding-right: 0,
      ),
      $em-values: true
    );
    background: $color-primary;
    color: #fff;
    margin: 0;
  }

  &__text {
    background: $superlight-grey;
    @include rms-value(
      $properties: (
        padding-top: -2,
        padding-bottom: -2,
        padding-left: 0,
        padding-right: 0,
      ),
      $em-values: true
    );
  }
}

.checkout-box .login {
  @extend .margin-top-small;
}

label.inline {
  @include rms-value(
    $properties: (
      margin-left: 0,
    )
  );
}

.form-row label {
  @extend legend;
}

//.required {
//  border-bottom: none !important;
//}

.product-name {
  img {
    @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
      display: none;
    }

    @include rms-value(
      $properties: (
        margin-right: 0,
      )
    );
    max-width: 80px;
  }
}

.woocommerce-checkout-review-order-table tr:not(.cart_item) td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.about_paypal {
  display: none;
}

.payment-method-container {
  &:not(:last-child) {
    @include rms-value(
      $properties: (
        margin-bottom: 0,
      )
    );
  }

  .payment_box {
    display: none !important;
  }
}

.payment-method-icon-container,
.payment-method-container {
  display: flex;
  align-items: center;
}

.payment-method {
  @include rms-value(
    $properties: (
      margin-left: 0,
    )
  );
}

.payment-method-icon {
  @include rms-value(
    $properties: (
      margin-right: 0,
    )
  );
  display: block;

  img {
    width: 80px;
    height: 40px;
    object-fit: scale-down;
    object-position: center;

    @include mq($breakpoint-tiny-alt) {
      width: 100px;
    }
  }
}

.payment-method-fields {
  @extend %last-child-spacing;
  @include rms-value(
    $properties: (
      padding-top: -6,
      padding-bottom: -6,
      padding-left: 0,
      padding-right: 0,
    ),
    $em-values: true
  );
  background-color: $superlight-grey;
  display: none;
}

input[type="radio"]:checked + label.payment-method {
  .payment-method-fields {
    display: block;
  }
}

.woocommerce-input-wrapper {
  display: block;
}
