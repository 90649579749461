.time-quote-logo {
  max-width: 150px;
}

.press-and-awards {

  &__item {
    @extend %small-scr-block-item-spacing;
    @extend %last-child-spacing;
    vertical-align: top;
    
    @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
      
    }
    
    @include mq($breakpoint-small, $breakpoint-medium - 1px) {
      @include generate-columns(3, 5, $ms-small-spacing);
    }
    
    @include mq($breakpoint-medium, $breakpoint-large - 1px) {
      @include generate-columns(3, 5, $ms-large-spacing);
    }
    
    @include mq($breakpoint-large) {
      @include generate-columns(3, 5, $ms-large-spacing);
    }
  }
  
  &__item-text {
  @include rms-value($properties: (font-size: 1, margin-top: 0));
  }
  
  img {
    max-width: 200px;
  }
  
  .award {
    @extend .margin-top-small;
    
    display: block;
    color: $color-title-text;
  }

}

.videos {
  .col-2-keep-small-scr {
    margin-bottom: 0;
  }
}