.testimonial {  
  &__img {
    @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
      
      img {
        max-width: 150px;
      }
      
      @include rms-value($properties: (margin-bottom: 0));
    }
    
    @include mq($breakpoint-tiny-alt) {
      width: 30%;
      margin-right: 5%;
    }
  }
  
  &__text {
    @include mq($breakpoint-tiny-alt) {
      width: 65%;
    }
  }  
  
  &__img,
  &__text {
    @include mq($breakpoint-tiny-alt) {
      vertical-align: top;
      display: inline-block;
    }
  }
}

.testimonial-vertical-center {
  display: flex;
  align-items: center;
}

.slider-arrow {
  @extend %absolute-center-vertical-only;
  @extend .title--1;
  cursor: pointer;
  
  &--prev {
    right: auto;
  }
  
  &--next {
    left: auto;
  }
}

.latest-offer {
  @extend %bg-img;
  height: 300px;
  position: relative;
  width: 100%;
  background-position: center center;
  
  &__text {
    @include transparent-bg($color-primary, 0.8);
    padding: 0.8em 1.6em;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
  }
  
  &__title {
    margin: 0;
    color: #FFF;
    display: inline-block;  
    @include mq($breakpoint-large - 1px, $default-query-type: max-width) {
      display: block;
    }
  }
  
  .btn {
    @include mq($breakpoint-large - 1px, $default-query-type: max-width) {
      @include rms-value($properties: (margin-top: 0));
    }
    
    @include mq($breakpoint-large) {
      float: right;
    }
  }
}

.about-photo {
  max-width: 250px;
  width: 100%;
}