.product-gallery {

  img {
    display: block;
  }

  &__thumbs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    @include rms-value($properties: (gap: -2));
  }

  &__thumb {
    @include rms-value($properties: (font-size: 5));
    border: 1px solid $color-title-text;
    outline: 0;
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__main {
    @include rms-value($properties: (margin-bottom: 0));
    display: block;
    outline: 0;
    overflow: hidden;
  }
}

.age-tag {
  @include rms-value($properties: (font-size: -1));
  border-radius: 5px;
  @include rms-value($properties: (padding-top: -6, padding-bottom: -6, padding-left: -2, padding-right: -2), $em-values: true);
  background: $superlight-grey;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.product-meta {
  @extend %no-list-style;
  @include rms-value($properties: (margin-bottom: 0));
  color: $color-primary;

  &__info {
    @include rms-value($properties: (margin-right: 1));
    display: inline-block;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }
  }

  &__price {
    @include rms-value($properties: (font-size: 2));
    color: $color-body-text;
    font-weight: $font-weight-normal-default;
  }
}

.product-qty {
  @include rms-value($properties: (margin-right: 1));
  vertical-align: middle;
  display: inline-block;
  width: 15%;
  min-width: 80px;
}

.product-variation {
  @include rms-value($properties: (margin-bottom: 0));

  &__label,
  &__value {
    display: inline-block;
  }
}

.single_variation .price {
  font-weight: $font-weight-normal-default;
}

.category-options {
  @extend .clearfix;
}

.result-count {
  font-weight: $font-weight-semibold-default;

  @include mq($breakpoint-medium) {
    float: left;
    @include rms-value($properties: (padding-top: -7), $em-values: true);
    margin-bottom: 0;
  }
}

.product-cat-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.product-cat {
  padding: 1rem;

  @include mq($breakpoint-tiny) {
    width: calc(100% / 2);
  }

  @include mq($breakpoint-small) {
    width: calc(100% / 3);
  }

  @include mq($breakpoint-medium) {
    width: calc(100% / 4);
  }

  @include mq($breakpoint-large) {
    width: calc(100% / 5);
  }
}

.product-cat img {
  width: auto !important;
  height: auto !important;
  max-height: 120px;
}

.manufacturer {
  @include rms-value($properties: (font-size: -1));
  @include rms-value($properties: (margin-bottom: -10));
  display: block;

  a {
    border-bottom: none !important;
  }
}

.ywgc-preview {
  display: none !important;
}

.ywgc-recipient {
  max-width: 100% !important;
  display: block;
}

.gift-card-content-editor textarea,
.gift-card-content-editor input[type="text"],
.gift-card-content-editor input[type="email"] {
  border-radius: inherit !important;
}

.gift-card-content-editor textarea {
  border-color: inherit !important;
}

.awards {
  @include rms-value($properties: (margin-left: -10, margin-right: -10), $minus-values: true);
  display: flex;
  flex-wrap: wrap;

  &__thumb {
    @include rms-value($properties: (margin-bottom: -10, padding-left: -10, padding-right: -10));
    line-height: 150px;

    img {
      max-height: 125px;
    }
  }
}

.bundled-products-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.bundled_product_summary {
  display: flex;
  word-break: break-word;
  padding: 1rem;

  @include mq($breakpoint-tiny) {
    width: calc(100% / 2);
  }

  @include mq($breakpoint-small) {
    width: calc(100% / 3);
  }

  @include mq($breakpoint-medium) {
    width: calc(100% / 2);
  }

  @include mq($breakpoint-large-alt) {
    width: calc(100% / 3);
  }

  .bundled_product_images {
    @include rms-value($properties: (margin-right: 0));
    flex: 0 0 60px;

    figure {
      margin: 0;
    }
  }

  .details {
    flex-grow: 1;

    a {
      border-bottom: none !important;
    }
  }
}

.bundle_price {
  .total {
    font-weight: $font-weight-bold-default;
  }
}

.single-product {
  .breadcrumb__path {
    span:nth-of-type(3):not(:last-child),
    span:nth-of-type(4):not(:last-child),
    i:nth-of-type(3),
    i:nth-of-type(4) {
      display: none;
    }
  }
}

.alert_container {
  display: block !important;
}

.subscribe_for_interest_text {
  font-size: 1.125rem !important;
}

.product-img {
  padding-bottom: 100%;
  position: relative;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
