.header {
  @include rms-value($properties: (padding-top: 0, padding-bottom: 0));
  position: relative;
  z-index: 5000;
  background: #FFF;
  
  @include e(container) {
    @extend %container;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($breakpoint-medium) {
      align-items: flex-end;
    }
  }
  
  @include mq($breakpoint-small) {
    @include rms-value($properties: (padding-top: 4, padding-bottom: 4));
  }
  
  a:not(.btn) {
    @extend %inherit-link-color;
  }
}
  
.logo {

  a {
    display: block;
  }
  
  img {
    width: 100%;

    @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
      width: 78px;
      height: 80px;
    }

    @include mq($breakpoint-small) {
      height: 90px;
    }

    @include mq($breakpoint-medium) {
      height: 100px;
    }
    
    @include mq($breakpoint-large) {
      height: 120px;
    }
  }
  
  .small-logo {
    display: none;
    
    @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
      display: block;
    }
  }
  
  .big-logo {
    @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
      display: none;
    }
  }
}
  
.small-scr-menu {
  @extend %no-list-style;
	margin: 0;
  line-height: 1;
  text-align: right;
  
  @include e(item) {  	
    display: inline-block;
    vertical-align: middle;

    @include mq($breakpoint-tiny) {
      &:after {
        content: "|";
        @include rms-value($properties: (margin-right: -1, padding-left: -1));
      }
    }
    
    &:last-child {
      margin-right: 0;
      &:after {
        content: "";
        padding-left: 0;
        margin-right: 0;
      }
      @include mq($breakpoint-medium) {
        display: none;
      }
    }
    
    
    &:nth-child(1),
    &:nth-child(2) {
      @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
        display: none !important;
      }
    }

    &:nth-child(3) {
      @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
        display: none !important;
      }
    }
      
    &:nth-child(4) {
      @include mq($breakpoint-medium) {
        @include rms-value($properties: (margin-right: -1));
        
        &:after {
          content: "";
          padding-left: 0;
          margin-right: 0;
        }
      }
    }
    
    @include m(active) {
      a {
      	color: $color-primary;
      	font-weight: $font-weight-normal-default;
      	border-bottom: 1px solid $color-primary;
      }
    }
    
    &:last-child {
      margin-right: 0;
      @include mq($breakpoint-medium) {
        display: none;
      }
    }
    
    button {
    	line-height: 1.2 !important;
    	padding: 0.4em 1.6em !important;
    	
    	&:hover,
    	&:active {
      	background: $green !important;
    	}
    }
    
    @include mq($breakpoint-medium) {
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  @include mq($breakpoint-medium) {
    @include rms-value($properties: (font-size: -1));
  }
}

.nav-menu {
  @extend %no-list-style;
  @include rms-value($properties: (padding-top: 3, padding-bottom: 3));
  display: none;
  @include rms-value($properties: (line-height: 5));
  
	.nav-menu__item:hover > a {
  	@include mq($breakpoint-medium) {
    	background: $color-primary;
    	color: #FFF !important;
  	}
	}
	
	.nav-menu__sub-menu > .nav-menu__sub-item:hover > a {
  	@include mq($breakpoint-medium) {
    	background: $yellow;
    	color: $color-primary;
  	}
	}

  @include e(item) {
    border-bottom: 1px solid darken(white, 70%);
    vertical-align: middle;
    
    &:last-child {
      border-bottom: none;
    }
  
    > a {
      display: block;
      @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
        @include rms-value($properties: (padding-top: -8, padding-bottom: -8));
      }

      @include mq($breakpoint-medium) {
        line-height: 2;
        @include rms-value($properties: (font-size: 0));
        @include rms-value($properties: (padding-left: -2, padding-right: -2));
      }
      
    	@include mq($breakpoint-large) {
          @include rms-value($properties: (font-size: 2));
    	}
    	
    	@include mq($breakpoint-large-alt) {
          @include rms-value($properties: (padding-left: 1, padding-right: 1));
    	}
    }
  
    @include m(active) {
      > a {
      	color: $color-primary;
      	@include mq($breakpoint-medium) {
      	  background: $superlight-grey;
      	}
      }
    }
    
    @include m(parent) {
      > a:hover {
        cursor: default;
        color: inherit;
      }
    }
    
    @include mq($breakpoint-medium) {
      border-bottom: none;
      display: inline-block;
    }
    
    &:last-child {
      margin-right: 0
    }
  }
  
  &__sub-item {
    position: relative;
  }
  
  &__sub-menu {
    @extend %no-list-style;
    @include rms-value($properties: (line-height: 5));
    display: none;
    
    @include mq($breakpoint-medium) {
      @include rms-value($properties: (font-size: 0));
    }
    
    @include mq($breakpoint-medium) {
      display: block;
      transition: all 0.5s ease-in-out;
      opacity: 0;
      visibility: hidden;
    	position: absolute;
    	text-align: left;
    	background: $color-primary;
    	
    	.nav-menu__sub-menu & {
      	top: 0;
      	left: 100%;
      	background: $color-accent;
    	}
    }
    
    a {
      border-bottom: none !important;
      display: block;
      color: #FFF;
      
      @include mq($breakpoint-medium) {
        @include rms-value($properties: (padding-left: 2, padding-right: 2));
      }
      
      @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
        color: $color-title-text;
      }
      
    	.nav-menu__sub-menu & {
      	@include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
      	  @include rms-value($properties: (padding-left: 10));
      	}
      	
      	@include mq($breakpoint-medium) {
      	  color: $color-primary;
      	}
    	}
      
      &:hover {
        
      	.nav-menu__sub-menu & {
        	@include mq($breakpoint-medium) {
          	background: lighten($color-primary, 30%) !important;
          	color: #FFF !important
        	}
      	}
      }
      
      @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
        @include rms-value($properties: (padding-left: 4));
      }
    }

    li:hover > & {
    	opacity: 1;
    	visibility: visible;
    }
  }
  
  @include mq($breakpoint-medium) {
    @include rms-value($properties: (margin-top: 0));
    display: block;
    text-align: right;
    padding: 0;
  }
  
  @include e(sub-item) {
    white-space: nowrap; 
    @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
      border-bottom: 1px solid darken(white, 70%);
  
      &:first-child {
        border-top: 1px solid darken(white, 70%);
      }
      
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.dt-sub-menu {
  display: none;

  &__inner {
    margin: 0 auto;
    max-width: 1100px;
  }
  
  @include mq($breakpoint-medium) {
    @include rms-value($properties: (padding: 3));
    display: block;
    opacity: 0;
    visibility: hidden;
    text-align: left;
  	position: absolute;
    left: 0;
  	right: 0;
  	background: #FFF;
  	border-top: none;
  	z-index: 5000;
    border-bottom: 15px solid $superlight-grey;

  	
    li:hover > & {
    	opacity: 1;
    	visibility: visible;
    }
  
  }
  
  .btn {
    color: #FFF !important;
  }
}

.dt-sub-menu-shop-all {
  @include rms-value($properties: (margin-top: 2, padding-top: 1));
  text-align: center;

  a {
    border-bottom: 1px solid $color-primary;
    display: inline-block;
    padding-bottom: 0.02em;
  }
}

#slide-menu:not(.mm-menu) {
  display: none;
}

.small-scr-nav-plus {
  float: right;
  line-height: 1.6;
  cursor: pointer;
  
  @include mq($breakpoint-medium) {
    display: none;
  }
}

.cart-btn {
  @extend .btn;
  color: #FFF !important;
  font-weight: $font-weight-light-default !important;
  font-family: inherit;

  @include mq($breakpoint-large) {
    padding: 0.4em 1.6em !important;
  }
}

.cart-number {
  display: inline-block;
  background: #FFF;
  color: $color-body-text !important;
  text-align: center;
  padding: 0 0.3em;
  //line-height: 1.2;
}

.header-notice {
  @extend .message;
  //background: lighten($yellow, 25%);
  background: $superlight-grey;
  
  strong {
    display: block;
  }
}

.mobile-currencies {
  text-align: center;
  
  @include mq($breakpoint-tiny-alt) {
    display: none !important; 
  }
  
  &__active {
    font-weight: $font-weight-normal-default;
    border-bottom: 1px solid $white;
  }
  
  a {
    color: inherit;
    padding: 0 10px;
  }
}

.categories-menu {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-right: -2rem;

  &__column {
    padding: 2rem;
    width: calc(100% / 4);
  }

  &__column__big {
    padding: 2rem;
    width: 35%;
  }

  &__column__small {
    padding: 2rem;
    width: 15%;
  }

  &__img {
    max-width: 220px;
    width: 100%;
  }
}