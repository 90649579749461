// ==========================================================================
// = Global Variables
// ==========================================================================

// Image URL for background images
$img-url: "../../img/";

/**
 * CSS layout debugging
 * @link http://pesticide.io/
 * @type boolean
 */

$pesticide-debug              : false;

/**
 * Define width for browsers w/out media query support
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$fix-mqs                      : false !default;

/**
 * Turn on/off IE specific styles
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$old-ie                       : false !default;

// Color Scheme
// examples based on the official Sass styleguide
// =============

// Descriptive colors:
$white                        : #fff;
$black                        : #000;
$dark-grey                    : lighten($black, 10%);
$light-grey                   : lighten($black, 30%);
$lighter-grey                 : lighten($black, 80%);
$superlight-grey              : #f2f2f2;
$blue                         : #2772b7;
$red                          : #f00038;
$green                        : #2db517;
$yellow                       : #fcdc0d;
$purple                       : #724099;
$orange                       : #f07700;

// Main color palette
$color-primary                : $purple;
$color-secondary              : $orange;
$color-accent                 : $green;

// Text
$color-title-text             : #061a3b;
$color-body-text              : lighten($color-title-text, 5%);

// Links
$color-link                   : $color-primary;
$color-link-hover             : $green;
$color-link-visited           : $color-primary;

// Code
$color-code                   : #333;
$color-code-background        : #f3f3f3;
$color-pre                    : #d4d4d4;
$color-pre-background         : #333;

// Selections
$color-selection              : #b3d4fc;

// Messages
$color-message                : #f4ecbb;
$color-success                : #2cde2c;
$color-warning                : #cf8600;
$color-important              : #d00;
$color-notice                 : #66b;


// Base Sizes
// ==========

/**
 * Base font size in used in _mixins.scss
 * @type number
 */

$base-font-size               : 16;


// Font Stacks
// ===========

/**
 * (optional) URL for Google Fonts import
 * @type string
 */

// $google-fonts-url          : 'http://fonts.googleapis.com/css?family=Source+Sans+Pro|Source+Code+Pro';

/**
 * (optional) names of webfonts imports
 * @type array
 */

// $fonts                     : 'FONTNAME', 'FONTNAME', 'FONTNAME', 'FONTNAME';

/**
 * define font stack used for monospaced copy
 * @type string
 */

$font-family-monospace        : 'source-code-pro','Inconsolata', "Menlo", Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
                                'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L',
                                Monaco, 'Courier New', Courier, monospace;

/**
 * define font stack used for headings
 * @type string
 */

$font-family-display            : "myriad-std-tilt", "Helvetica Neue", Helvetica, Arial, sans-serif;
//$font-weight-light-display    : 300;
$font-weight-normal-display   : 400;
$font-weight-semibold-display : 400;
$font-weight-bold-display     : 400;

/**
 * define font stack used for general body text such as paragraphs
 * @type string
 */

$font-family-default          : "museo-sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-weight-light-default    : 300;
$font-weight-normal-default   : 500;
$font-weight-semibold-default : 700;
$font-weight-bold-default     : 700;

/**
 * define font stack used for alternate text
 * @type string
 */

$font-family-alt          : "ff-dax-compact-web-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-weight-normal-alt   : 400;


// Media query breakpoints
// Assume min-width (by default) if only a number
// ============
$breakpoint-tiny              : 480px;
$breakpoint-tiny-alt          : 600px;
$breakpoint-small             : 768px;
$breakpoint-medium            : 1024px;
$breakpoint-large             : 1260px;
$breakpoint-large-alt         : 1400px;
$breakpoint-huge              : 1600px;
$breakpoint-enormous          : 1900px;
$breakpoint-hi-rez            : min-resolution 1.5dppx;

/**
 * Define z-indexes for various elements
 * @link http://www.sitepoint.com/using-sass-maps/
 * @type number
 */

// z-index reference
$zindex: (
  modal                       : 9000,
  overlay                     : 8000,
  dropdown                    : 7000,
  header                      : 6000,
  footer                      : 5000
);

// Modular scale variables
// ============
$ms-base: 1rem;
$ms-ratio-default: $major-second;
$ms-ratio-large: $minor-third;
$ms-small-spacing: 9;
$ms-large-spacing: 13;
$ms-xlarge-spacing: 18;

$modularscale: (
  base: 1rem,
  ratio: $major-second
);


// Gutters
// ============
$tiny-gutter: 2rem;
$small-gutter: 3rem;
$medium-gutter: 4rem;
$large-gutter: 5rem;