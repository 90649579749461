.message {
  @include rms-value($properties: (padding-top: -6, padding-bottom: -6, padding-left: 0, padding-right: 0), $em-values: true);
  
  &--success {
    background: lighten($green, 35%);
    border: 1px solid $green;
    color: darken($green, 30%);
  }
  
  &--error {
    background: lighten($red, 35%);
    border: 1px solid $red;
    color: darken($red, 30%);
  }
  
  &--notice {
    background: lighten($blue, 35%);
    border: 1px solid $blue;
    color: darken($blue, 30%);
  }
  
  // Hide Woocommerce buttons added to messages
  .button {
    display: none;
  }
}

//.alert_container {
//	@include rms-value($properties: (padding-bottom: 0));
//}

.stock_alert_email {
	margin-bottom: 10px;
	width: 250px;
}

h6.subscribe_for_interest_text {
	margin-bottom: 5px;
}

.alert_container_bk {
	display: none;
}

.registered_message {
  @extend .message;
  @extend .message--success;
}