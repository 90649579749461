.cart {
  th {
    background: $color-primary;
    color: #fff;
  }

  td {
    // &:not(:first-child) {
    //   @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
    //     padding-top: 0;
    //   }
    // }

    background: $superlight-grey;
    @include rms-value(
      $properties: (
        padding-top: 0,
        padding-bottom: 0,
      ),
      $em-values: true
    );
  }

  tr {
    border-bottom: 5px solid #fff;
  }

  .product-quantity {
    p {
      margin-bottom: 0;
      display: inline-block;
    }

    .qty {
      max-width: 100px;
    }
  }

  .product-name {
    @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
      font-weight: $font-weight-normal-default;
    }
  }

  .product-thumbnail img {
    border: 1px solid $lighter-grey;
    height: auto !important;
    max-width: 150px !important;
    width: 100% !important;
  }

  .product-thumbnail {
    @include mq($breakpoint-tiny-alt, $breakpoint-small - 1px) {
      display: none;
    }
  }

  .variation {
    dt,
    dd {
      display: inline-block;
      margin: 0;

      p {
        margin: 0;
        display: inline;
      }
    }
  }
}

.order-table {
  th {
    background: $color-primary;
    color: #fff;
  }

  td {
    background: $superlight-grey;
    @include rms-value(
      $properties: (
        padding-top: 0,
        padding-bottom: 0,
      ),
      $em-values: true
    );
  }

  tr {
    border-bottom: 5px solid #fff;
  }

  .variation {
    dt,
    dd {
      display: inline-block;
      margin: 0;

      p {
        margin: 0;
        display: inline;
      }
    }
  }
}

.remove-small-scr-padding {
  td {
    &:not(:first-child) {
      @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
        padding-top: 0;
      }
    }
  }
}

.responsive-tbl {
  @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
    display: block;

    tfoot,
    th,
    tr,
    td {
      display: block;
      width: 100%;
    }

    tbody {
      display: flex;
      flex-wrap: wrap;
    }

    thead {
      display: none;
    }

    .responsive-data-label:before {
      content: "" attr(data-label) ": ";
      font-weight: $font-weight-normal-default;
    }

    .responsive-data-title:before {
      content: "" attr(data-title) ": ";
      font-weight: $font-weight-normal-default;
    }
  }

  tr:not(.small-scr-full-width) {
    @include mq($breakpoint-tiny, $breakpoint-tiny-alt - 1px) {
      @include generate-columns(2, 2.5);
    }
  }
}

.coupon-input {
  @include rms-value(
    $properties: (
      margin-right: 0,
    )
  );
  width: auto;
  vertical-align: top;
}

.update-cart-btn {
  @include mq($breakpoint-small) {
    float: right;
  }

  &.show-small-scr {
    @include mq($breakpoint-small) {
      display: none;
    }
  }

  &.hide-small-scr {
    @include mq($breakpoint-small - 1px, $default-query-type: max-width) {
      display: none;
    }
  }
}

.cart-totals {
  @include mq($breakpoint-small) {
    width: 50%;
    float: right;
  }

  table {
    table-layout: fixed;
  }

  th {
    background: $color-primary;
    color: #fff;
    width: 30%;
  }

  td {
    background: $superlight-grey;
    width: 70%;
  }

  tr {
    border-bottom: 5px solid #fff;
  }
}

#calc_shipping_country {
  width: 100%;
}

.wc-proceed-to-checkout {
  @include mq($breakpoint-small) {
    text-align: right;
  }
}

.order-table--2-col td {
  @include mq($breakpoint-tiny-alt) {
    width: 50%;
  }
}
