.contact-info-margin-btm {
  
  @include mq($breakpoint-tiny - 1px, $default-query-type: max-width) {
    @include rms-value($properties: (margin-bottom: 0));
  }
  
  @include mq($breakpoint-tiny) {
    margin-bottom: 0 !important;
  }
  
}

.blackboard {
  
  img {
    @include mq($breakpoint-tiny-alt - 1px, $default-query-type: max-width) {
      display: none;
    }
  }
  
  .title {
    @include mq($breakpoint-tiny-alt) {
      color: $white; 
    }
  }
  
  @include mq($breakpoint-tiny-alt) {
    @include rms-value($properties: (margin-top: $ms-small-spacing));
    position: relative;
  }
  
  &__inner {
    
    @include mq($breakpoint-tiny-alt) {
      position: absolute;
      padding: 7%;
      color: #FFF;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0; 
    }
    
    @include mq($breakpoint-small, $breakpoint-medium - 1px) {
      padding: 15%;
    }
    
    @include mq($breakpoint-large) {
      padding: 15%;
    }
  }
  
  a {
    @include mq($breakpoint-tiny-alt) {
      @include link-colors($white, $hover: $color-link-hover);
      border-bottom: none !important;
    }
  }
}

.contact-social {
  @extend %no-list-style;
}

div.wpcf7-validation-errors {
    border: 1px solid #B5AF57 !important;
    background: #F7D85A none repeat scroll 0% 0%;
}
div.wpcf7-response-output {
    padding: 5px 10px !important;
    margin-bottom: 10px;
}
.wpcf7-display-none, .screen-reader-response {
    display: none;
}
span.wpcf7-not-valid-tip {
    display: block;
    z-index: 100;
    background: #FFF none repeat scroll 0% 0%;
    color: #F00;
    margin: 0px;
}