fieldset {
  padding: 0;
  margin: 0;
  border: none;
  @include rms-value($properties: (margin-bottom: 0));
}

select,
input,
.affwp-form input,
textarea {
  @include rms-value($properties: (padding-top: -6, padding-bottom: -6, padding-left: 0, padding-right: 0), $em-values: true);
  width: 100%;
  border-radius: 0;
  border: 1px solid $color-title-text;
  outline: 0;
  margin: 0;
  
  &:focus {
    border: 1px solid $color-primary;
  }

}

input[type='radio'],
input[type='checkbox'] {
  width: auto;
  padding: 0;
}

select {
  @include rms-value($properties: (padding-right: 10), $em-values: true);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
	cursor: pointer;
	text-shadow: none;
	background: $color-primary url('#{$img-url}dropdown-arrow-white.png') no-repeat center right;
	background-size: 28px 5px !important;
	color: #FFF;
	border: none !important;
	width: auto;
 
  &.white-select {
    background: #FFF url('#{$img-url}dropdown-arrow.png') no-repeat center right;
    color: inherit;
    border: 1px solid $color-title-text !important;
    width: 100%;
    line-height: normal;
  
    &:focus {
      border: 1px solid $color-primary !important;
    }
  }
}

textarea {
  min-height: 150px;
}

.radio-group {
  display: table;
  width: 100%;
  
  &--break-small {
    @include mq($breakpoint-tiny - 1px, $default-query-type: max-width) {
      display: block;
      
      label {
        display: block !important;
        height: auto;
        border-radius: 0;
        //border-right: none !important;
        //border-bottom: 1px solid $white;
        
        &:first-of-type {
          border-top-right-radius: 3px;
          border-bottom-left-radius: 0 !important;
        }
        
        &:last-of-type {
          border-top-right-radius: 0 !important;
          border-bottom-left-radius: 3px;
          //border-bottom: none !important;
        }
      }
    }
  }
  
  input[type="radio"] {
    display: none;
  }
  
  label {
    @extend %transition;
    @include rms-value($properties: (padding-top: -6, padding-bottom: -6, padding-left: 0, padding-right: 0), $em-values: true); /* 1 */
    display: table-cell;
    vertical-align: top;
    line-height: inherit;
    //border-right: 1px solid $white;
    border: 5px solid $superlight-grey;
    text-align: center;
    cursor: pointer;
    background: $superlight-grey;
    color: $black;
    opacity: 0.7;
    
    &.payment-method {
      @include mq($breakpoint-tiny) {
        width: 50%;
      }
    }
    
    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    
    &:last-of-type {
      border-top-right-radius: 3px;
      //border-bottom-right-radius: 3px;
      //border-right: none;
    }
    
    //&:hover {
    //  background-color: $color-secondary !important;
    //  color: $white;
    //}
    
    span {
      @include rms-value($properties: (font-size: -2));
      display: block;
      text-transform: none;
      letter-spacing: normal;
    }
  }
  
  input[type="radio"]:checked + label {
    border: 5px solid $blue;
    opacity: 1;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.filters {
  
  @include mq($breakpoint-medium) {
    float: right;
  }
  
  &__filter {
    @extend .clearfix;
    text-align: left;
    
    @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
      @include rms-value($properties: (margin-bottom: 0));
      
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include mq($breakpoint-medium) {
      @include rms-value($properties: (margin-right: 2));
      display: inline-block;
      
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  
  &__select {
    @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
      float: right;
      width: 75%;
      display: block;
    }
  }
  
  &__text {
    @include mq($breakpoint-medium - 1px, $default-query-type: max-width) {
      float: left;
      width: 25%;
    }
    display: inline-block;
  }
}

.form-row,
.affwp-form input {
  max-width: 400px;
}

.affwp-send-notifications-wrap label {
  display: inline-block;
  margin: 0;
}