.my-account-nav {
  @extend .no-list-style;
  @include rms-value($properties: (margin-bottom: 0));

  li {
    display: inline-block;
    margin-bottom: 5px;

    &:after {
      content: "|";
      font-weight: $font-weight-normal-default !important;
      @include rms-value($properties: (margin-right: -6, padding-left: -6));
    }

    &:last-child {
      margin-right: 0;

      &:after {
        content: "";
        padding-left: 0;
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      border-bottom: none !important;
    }
  }
}